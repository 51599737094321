import { useEffect } from 'react';
import { DAEMON, useBrowserOnlyInjectSagaAndReducer } from '@dbh/redux-extra';
import { List, Map, fromJS } from 'immutable';
import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { LOAD_WEBPAGES_SUCCESS } from '@dbh/webpages-events-and-constants-redux';
import { isServerSideRendering } from '@dbh/environment';
import { NAVIGATED_TO_NEW_TAB_OR_WINDOW } from '@dbh/navigate-to-new-tab-or-window';
import { makeSelectReactRouterPageType, makeSelectCurrentRouterState } from '@dbh/routing-redux';
import { selectLocationOriginFromServer } from '@dbh/ssr-data-redux';
import '@dbh/throw-in-server-side-rendering';
import { makeSelectTemplateCode, makeSelectWebpagesLoadedAll, makeSelectCurrentWebpageRouteReference, makeSelectWebpagesRoutes, getWebpageRouteByUrlPathnameOrHash } from '@dbh/webpages-redux';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import { makeSelectGtagDataToSendWithEveryEvent, analyticsEventSent } from '@dbh/analytics-redux';
import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';
import { isDBHDomainUrl } from '@dbh/domain-utils';
import { isRelativeUrl, getUrlPartsFromRelativeUrl } from '@dbh/urls';
import { ADMIN_PAGE_REFERENCES_ARRAY_FOR_VALIDATION_ONLY } from '@dbh/admindev-constants';
import { makeSelectSagaRequestOptions } from '@dbh/saga-request-options';
import { API_IDS } from '@dbh/api-constants';
import { makeSelectApiUrl } from '@dbh/configuration-redux';
import { request } from '@dbh/request-queue-redux';
import { createSelector } from 'reselect';
import _noop from 'lodash/noop';
import 'react-immutable-proptypes';

/*
 *
 * Constants: `@dbh/navigation-history-redux`.
 *
 */const REDUX_AND_SAGA_KEY="navigationHistory";const PAGE_LOCATION_CHANGED="navigationHistory/PAGE_LOCATION_CHANGED";const PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY="navigationHistory/PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY";const PAGE_DATA_PUT_INTO_LOCAL_STORAGE="navigationHistory/PAGE_DATA_PUT_INTO_LOCAL_STORAGE";const NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE="navigationHistory/NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE";const NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE="navigationHistory/NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE";const LOCAL_STORAGE_KEYS={NAVIGATION_HISTORY:"dbhNavigationHistory"};

/**
 * Dispatched when the user navigates to a new page.
 * @return {Object} .
 */const pageLocationChanged=()=>({type:PAGE_LOCATION_CHANGED});const usePageLocationChanged=createUseBoundEventCreator(pageLocationChanged);var pageLocationChanged$1 = pageLocationChanged;

/**
 * @typedef {import('.').PageData} PageData
 *//**
 * @typedef {Immutable.List<PageData>} NavigationHistory
 */const navigationHistoryImmutablePropType=_noop;

/**
 * @typedef {import('../types').PageData} PageData
 *//**
 * Dispatched when the user navigates to a new page, to add the page location to
 * the navigation history.
 * @param {PageData} pageData .
 * @return {Object} .
 */const pageLocationAddedToNavigationHistory=withConformsTo("pageLocationAddedToNavigationHistory",[],a=>({type:PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY,payload:{pageData:a}}));var pageLocationAddedToNavigationHistory$1 = pageLocationAddedToNavigationHistory;

/**
 * @typedef {import('../types').PageData} PageData
 *//**
 * Dispatched when the user navigates to a new page, to put the navigation history
 * into the local storage.
 * @param {PageData} newTabOrWindowPageData .
 * @return {Object} .
 */const navigationHistoryPutIntoLocalStorage=withConformsTo("navigationHistoryPutIntoLocalStorage",[],a=>({type:PAGE_DATA_PUT_INTO_LOCAL_STORAGE,payload:{newTabOrWindowPageData:a}}));const useNavigationHistoryPutIntoLocalStorage=createUseBoundEventCreator(navigationHistoryPutIntoLocalStorage);var navigationHistoryPutIntoLocalStorage$1 = navigationHistoryPutIntoLocalStorage;

/**
 * Dispatched when the user closes the browser window or tab, to remove the navigation
 * history from the local storage.
 * @param {string} localStorageKey .
 * @return {Object} .
 */const navigationHistoryRemovedFromLocalStorage=withConformsTo("navigationHistoryRemovedFromLocalStorage",[],a=>({type:NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE,payload:{localStorageKey:a}}));const useNavigationHistoryRemovedFromLocalStorage=createUseBoundEventCreator(navigationHistoryRemovedFromLocalStorage);var navigationHistoryRemovedFromLocalStorage$1 = navigationHistoryRemovedFromLocalStorage;

/**
 * @typedef {import('../types').NavigationHistory} NavigationHistory
 *//**
 * Dispatched when we want to sync the navigation history state with the local
 * storage.
 * @param {NavigationHistory} navigationHistoryFromLocalStorage .
 * @return {Object} .
 */const navigationHistoryRestoredFromLocalStorage=withConformsTo("navigationHistoryRestoredFromLocalStorage",[],a=>({type:NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE,payload:{navigationHistoryFromLocalStorage:a}}));var navigationHistoryRestoredFromLocalStorage$1 = navigationHistoryRestoredFromLocalStorage;

const initialState=List(),navigationHistoryReducer=function(a,b){switch(void 0===a&&(a=initialState),b.type){case NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE:{const{payload:{navigationHistoryFromLocalStorage:a}}=b;return a}case PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY:{const{payload:{pageData:c}}=b;return a.push(c)}default:return a}};/**
 * Manages the navigation history state.
 * @param {Immutable.Map} state The current state.
 * @param {Object} event .
 * @return {Immutable.Map} The next state.
 */var navigationHistoryReducer$1 = navigationHistoryReducer;

const getNavigationHistoryLocalStorageKey=withConformsTo("getNavigationHistoryLocalStorageKey",[],a=>(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY+"-"+decodeURIComponent(a)));var getNavigationHistoryLocalStorageKey$1 = getNavigationHistoryLocalStorageKey;

const abbreviations={UserArea:"UA",Request:"Req",DevOnly:"Dev",DevelopmentOnly:"Dev",Corporate:"Corp",Password:"Pwd",Reservation:"Resv",Information:"Info",InvoicesPaymentFormPage:"InvoicesForm",ViewInvoicesPaymentPage:"ViewInvoices",InsertSignUpVerificationCode:"SignUpVerCode",InsertNewUserPassword:"InsNewUserPwd",Page:"Pg",Reset:"Rst",Category:"Cat",Lp:"","lp-dashboard-loyalty-user":"LoyaltyUserPg","lp-content-page-not-loyalty-user":"NotLoyaltyUserPg","about-us":"AboutUs",Dashboard:"Dash",PhilosophyAndBenefits:"PAndB",termsandconditions:"TAndC",HowToEnableJsInBrowser:"JsEnableInBrowsr",Success:"Sucs"},regexToReplace=new RegExp(Object.keys(abbreviations).join("|"),"g"),replaceAbbreviations=a=>a.replace(regexToReplace,a=>abbreviations[a]),buildGtagNavigationEventName=withConformsTo("buildGtagNavigationEventName",[],a=>{let{fromPageType:b,toPageType:c,wasOpenedInNewTabOrWindow:d}=a;const e=replaceAbbreviations(b),f=replaceAbbreviations(c),g="Nav_"+e+"_"+f+(d?"_N":"");// Event names must start with a letter. Use only letters, numbers, and
// underscores. Don't use spaces.
// @see {@link https://support.google.com/analytics/answer/13316687?hl=en#:~:text=Event%20names%20can%20include%20English,letters%2C%20numbers%2C%20and%20underscores}
return g});/**
 * Returns the name of the `gtag` `GA4` user navigation event, not longer
 * than `40` characters.
 * @param {Object} options .
 * @param {string} options.fromPageType The type of the page the user navigated from.
 * @example `UserAreaResetPasswordRequestSuccess`.
 * @param {string} options.toPageType The type of the page the user navigated to.
 * @example `ViewFirstReservationPage`.
 * @return {string} The name of the `gtag` `GA4` user navigation event.
 */var buildGtagNavigationEventName$1 = buildGtagNavigationEventName;

const selectNavigationHistoryState=a=>a[REDUX_AND_SAGA_KEY];var selectNavigationHistoryState$1 = selectNavigationHistoryState;

/**
 * @typedef {import('../types').PageData} PageData
 *//**
 * Fires the custom `gtag` `GA4` user navigation events.
 * @param {PageData?} newTabOrWindowPageData The location of the page the user
 * navigated to in a new tab/window.
 * @yields {void} .
 */const handleGtagNavigationEvent=withConformsTo("handleGtagNavigationEvent",[],function*(a){const b=yield select(makeSelectGtagDataToSendWithEveryEvent());let c,d,e,f,g,h,i;if(!a){const a=yield select(selectNavigationHistoryState$1),b=a.last();h=null==b?void 0:b.get("pageLocation"),f=null==b?void 0:b.get("pageType"),g=null==b?void 0:b.get("adminPageReference");const i=null==a?void 0:a.get(a.size-2);d=null==i?void 0:i.get("pageLocation"),c=null==i?void 0:i.get("pageType"),e=null==i?void 0:i.get("adminPageReference");const j=f===c&&h===d;if(!c||j)return}else {const{adminPageReference:j,page_type:k,page_location:l}=b;c=k,e=j,d=l,f=a.get("pageType"),g=a.get("adminPageReference"),h=a.get("pageLocation"),i=a.get("wasOpenedInNewTabOrWindow");}const j=Map({eventPastTense:buildGtagNavigationEventName$1({fromPageType:e||c,toPageType:g||f,wasOpenedInNewTabOrWindow:i}),eventCategory:"UserNavigation",fromPageLocation:d,fromPageType:c,toPageLocation:h});yield call(analyticsEventSent,j.merge(b));});var handleGtagNavigationEvent$1 = handleGtagNavigationEvent;

/**
 * Handles the navigation change.
 * @yields {void} .
 */function*handleNavigationChange(){const a=yield select(makeSelectTemplateCode()),b=yield select(makeSelectReactRouterPageType()),{locationBeforeTransitions:{pathname:d,search:e,hash:f},previousLocationBeforeTransitions:c}=yield select(makeSelectCurrentRouterState()),{hash:g}=c||{hash:""},h=yield select(makeSelectWebpagesLoadedAll()),i=1===h.size,j=i&&!(f!==g),k=yield select(selectLocationOriginFromServer),l=""+k+d+e+f,m=yield select(makeSelectCurrentWebpageRouteReference()),n={pageLocation:l,pageType:a||b,...(m?{adminPageReference:m}:{}),ts:Date.now()};// `React` Router page type is used as a fallback in case the webpages template
// code is not available due to a redirect to the `TopMenuHashPage`.
// To Reproduce: From the navigation bar, navigate to "Your Bookings" While
// The user is not logged in. The user will be redirected to the sign in page.
// So we want to track the redirect navigation event.
if(j){// The navigation history is stored in the `localStorage` when the user
// navigates to a page in a new tab or window.
// We retrieve the navigation history from the `localStorage` and add it to
// the `navigationHistory` state.
// After that, we remove the navigation history from the `localStorage`.
const a=getNavigationHistoryLocalStorageKey$1(l),b=localStorage.getItem(a);b?(yield put(navigationHistoryRestoredFromLocalStorage$1(fromJS(JSON.parse(b)))),n.wasOpenedInNewTabOrWindow=!0,yield put(navigationHistoryRemovedFromLocalStorage$1(a))):n.isFirstPageAfterSsr=!0;}// Don't use `yield all` here because we want to keep the order of the events.
yield put(pageLocationAddedToNavigationHistory$1(Map(n))),j||(yield call(handleGtagNavigationEvent$1));}

/**
 * Fetches the `/webpages` `API` data, for a link loaded in a new tab or window.
 * @param {string} pathname .
 * @return {Object} .
 * @yields {Object} .
 */const fetchNewTabOrWindowPageData=withConformsTo("fetchNewTabOrWindowPageData",[],function*(a){const b=yield select(makeSelectSagaRequestOptions(API_IDS.webpages)),c={queryParameters:Map({url:a,_makeUrlRelative:!0,_fields:"url,templateCode,reference"})},d=yield select(makeSelectApiUrl(API_IDS.webpages,c)),{apiResponse:e,emptyResponse:f}=yield call(request,d,b);if(!f){const{"hydra:member":[a]}=e;return a}});var fetchNewTabOrWindowPageData$1 = fetchNewTabOrWindowPageData;

/**
 * Returns the page type of the page.
 * @param {Immutable.Map} webpageRoute The "Web Page" route in the `admindev`.
 * @param {string} pathname .
 * @yields {object?} The new tab or window page data.
 * @return {string?} The page type of the page.
 */function*getPageType(a,b){if(a){const b=a.get("reference"),c=ADMIN_PAGE_REFERENCES_ARRAY_FOR_VALIDATION_ONLY.includes(b);return c?b:a.get("templateCode")}const c=yield call(fetchNewTabOrWindowPageData$1,b);return c?c.templateCode:null}

/**
 * Handles the navigation to a new tab.
 * @param {Object} event .
 * @param {Object} event.payload .
 * @param {string} event.type .
 * @param {string} event.payload.url .
 * @yields {void} .
 */const handleNewTabOrWindowNavigation=withConformsTo("handleNewTabOrWindowNavigation",[],function*(a){const{payload:{url:b}}=a,c=yield select(selectLocationOriginFromServer),d=isRelativeUrl(b);if(!d){const a=isDBHDomainUrl(new URL(b));if(a){const a=Map({pageLocation:b,pageType:"ExternalPage",ts:Date.now()});yield call(handleGtagNavigationEvent$1,a);}return}const e=getUrlPartsFromRelativeUrl(b),{hash:f,pathname:g}=e,h=yield select(makeSelectWebpagesRoutes()),i=getWebpageRouteByUrlPathnameOrHash(h,f||g),j=yield call(getPageType,i,g),k=Map({pageLocation:""+c+b,pageType:j,wasOpenedInNewTabOrWindow:!0,ts:Date.now()});yield call(handleGtagNavigationEvent$1,k),yield put(navigationHistoryPutIntoLocalStorage$1(k));});var handleNewTabOrWindowNavigation$1 = handleNewTabOrWindowNavigation;

/**
 * Removes location data from the local storage.
 * @param {Object} event .
 * @param {Object} event.payload .
 * @param {string} event.string .
 * @param {string} event.payload.localStorageKey .
 * @yields {void} .
 */const handleRemoveLocationDataFromLocalStorage=withConformsTo("handleRemoveLocationDataFromLocalStorage",[],function*(a){const{payload:{localStorageKey:b}}=a;localStorage.removeItem(b);});var handleRemoveLocationDataFromLocalStorage$1 = handleRemoveLocationDataFromLocalStorage;

/**
 * @typedef {import('../types').PageData} PageData
 *//**
 * Puts the navigation history into the local storage.
 * @param {Object} event .
 * @param {Object} event.payload .
 * @param {string} event.type .
 * @param {PageData} event.payload.newTabOrWindowPageData .
 */const handlePutNavigationHistoryIntoLocalStorage=withConformsTo("handlePutNavigationHistoryIntoLocalStorage",[],function*(a){const{payload:{newTabOrWindowPageData:b}}=a,c=b.get("pageLocation"),d=yield select(selectNavigationHistoryState$1),e=getNavigationHistoryLocalStorageKey$1(c);localStorage.setItem(e,JSON.stringify(d));});var handlePutNavigationHistoryIntoLocalStorage$1 = handlePutNavigationHistoryIntoLocalStorage;

function*rootNavigationTrackingSaga(){isServerSideRendering()||(yield all([takeLatest(LOAD_WEBPAGES_SUCCESS,handleNavigationChange),takeLatest(PAGE_LOCATION_CHANGED,handleNavigationChange),takeLatest(NAVIGATED_TO_NEW_TAB_OR_WINDOW,handleNewTabOrWindowNavigation$1),takeLatest(NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE,handleRemoveLocationDataFromLocalStorage$1),takeLatest(PAGE_DATA_PUT_INTO_LOCAL_STORAGE,handlePutNavigationHistoryIntoLocalStorage$1)]));}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON,saga:rootNavigationTrackingSaga};

const useBrowserOnlyInjectNavigationTrackingSagaAndReducerArgs=[{...injectedSagaConfiguration,saga:rootNavigationTrackingSaga},{key:REDUX_AND_SAGA_KEY,reducer:navigationHistoryReducer$1}],useBrowserOnlyInjectNavigationTrackingSagaAndReducer=()=>useBrowserOnlyInjectSagaAndReducer(...useBrowserOnlyInjectNavigationTrackingSagaAndReducerArgs);var useBrowserOnlyInjectNavigationHistorySagaAndReducer = useBrowserOnlyInjectNavigationTrackingSagaAndReducer;

const useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer=()=>{useBrowserOnlyInjectNavigationHistorySagaAndReducer();const a=usePageLocationChanged();useEffect(()=>{a();},[]);};var useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer$1 = useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer;

/**
 * Returns a selector that returns the first landing page in the navigation
 * history.
 * @return {Immutable.Map?} .
 */const makeSelectLandingPageFromNavigationHistory=()=>createSelector(selectNavigationHistoryState$1,a=>a?a.find(a=>!0===a.get("isFirstPageAfterSsr")):void 0);var makeSelectLandingPageFromNavigationHistory$1 = makeSelectLandingPageFromNavigationHistory;

export { LOCAL_STORAGE_KEYS, NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE, NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE, PAGE_DATA_PUT_INTO_LOCAL_STORAGE, PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY, PAGE_LOCATION_CHANGED, REDUX_AND_SAGA_KEY, makeSelectLandingPageFromNavigationHistory$1 as makeSelectLandingPageFromNavigationHistory, navigationHistoryImmutablePropType, navigationHistoryPutIntoLocalStorage$1 as navigationHistoryPutIntoLocalStorage, navigationHistoryRemovedFromLocalStorage$1 as navigationHistoryRemovedFromLocalStorage, navigationHistoryRestoredFromLocalStorage$1 as navigationHistoryRestoredFromLocalStorage, pageLocationAddedToNavigationHistory$1 as pageLocationAddedToNavigationHistory, pageLocationChanged$1 as pageLocationChanged, selectNavigationHistoryState$1 as selectNavigationHistoryState, useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer$1 as useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer, useNavigationHistoryPutIntoLocalStorage, useNavigationHistoryRemovedFromLocalStorage, usePageLocationChanged };
