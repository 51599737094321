import createUseBoundEventCreator from '@dbh/create-use-bound-event-creator';

const NAVIGATED_TO_HOME_PAGE="@dbh/navigate-to-home-page-redux/NAVIGATED_TO_HOME_PAGE";

/**
 * Triggers `react-router` `push` event that navigates to `HomePage`.
 * @param {Object} obj .
 * @param {boolean} obj.useReplace Should it use `react-router` `replace` instead
 * of `push`.
 * @return {Object} .
 */const navigatedToHomePage=function(a){let{useReplace:b}=void 0===a?{}:a;return {type:NAVIGATED_TO_HOME_PAGE,payload:{useReplace:b}}};const useNavigatedToHomePage=createUseBoundEventCreator(navigatedToHomePage);

export { NAVIGATED_TO_HOME_PAGE, navigatedToHomePage, useNavigatedToHomePage };
